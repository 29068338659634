import React, { useContext } from 'react';
import { graphql } from 'gatsby';

import { HouseContext } from '../contexts/houseContext';

import SEO from '../components/SEO';
import LongWalk from '../components/longWalk/LongWalk';
import Home from '../components/home/Home';

function IndexPage() {
	const { houseWrapFontSize } = useContext(HouseContext);

	return (
		<>
			<SEO title="Home" />
			<main id="main">
				{houseWrapFontSize && (
					<>
						<Home houseWrapFontSize={houseWrapFontSize} />
						<LongWalk houseWrapFontSize={houseWrapFontSize} />
					</>
				)}
			</main>
			
		</>
	);
}

// via page query
export const pageQuery = graphql`
	query {
		site {
			siteMetadata {
				title
				description
			}
		}
	}
`;
export default IndexPage;
