import React, { useContext } from 'react';
import { Link } from 'gatsby';
import * as classes from './home.module.scss';

import { DaylightContext } from '../../contexts/daylightContext';

export default function Home({ houseWrapFontSize }) {
	const { daylight } = useContext(DaylightContext);
	const useForPadding =
		houseWrapFontSize * 18 +
		houseWrapFontSize * 8 +
		houseWrapFontSize * 2 +
		1.5 * 16; // tallest house = 18em, footer = ~1.5, water  4em * 2 , wall = 2rem header = 5rem

	return (
		<div
			className={` ${
				daylight ? classes.homeContentWrap : classes.homeContentWrapDark
			}`}
			style={{ paddingBottom: `${useForPadding}px` }}
		>
			<h1
				className="hZero"
				style={{ color: daylight ? 'var(--nightSkyDarker)' : 'var(--white)' }}
			>
				Galway based <span>Web Developer</span>
			</h1>
			<p
				style={{
					color: daylight ? 'var(--roof4)' : 'var(--roof8)',
				}}
			>
				I build ultra fast, accessible websites and applications that search
				engines love.
			</p>
			{/* <Link className={classes.cta} to="/projects">
				See some Examples
				<span>
					<svg
						height="16"
						width="16"
						aria-label="right arrow"
						xmlns="http://www.w3.org/2000/svg"
						viewBox="0 0 448 512"
					>
						<path
							fill={`${daylight ? 'var(--houseBlue)' : 'var(--yellow)'}`}
							d="M438.6 278.6l-160 160C272.4 444.9 264.2 448 256 448s-16.38-3.125-22.62-9.375c-12.5-12.5-12.5-32.75 0-45.25L338.8 288H32C14.33 288 .0016 273.7 .0016 256S14.33 224 32 224h306.8l-105.4-105.4c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l160 160C451.1 245.9 451.1 266.1 438.6 278.6z"
						/>
					</svg>
				</span>
			</Link> */}
			{/* <p
				style={{
					color: daylight ? 'var(--roof4)' : 'var(--roof7)',
				}}
			>who builds fast, accessibile websites and applications.
		
	
			</p> */}
		</div>
	);
}
