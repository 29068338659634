import React from 'react';

export default function Cloud({ cloudClass }) {
	return (
		<svg
			className={cloudClass}
			width="1132.035"
			height="711.342"
			viewBox="0 0 299.518 188.209"
		>
			<defs />
			<path
				fill="#ececec"
				d="M148.955.007c-16.927-.24-33.024 12.11-36.997 28.597C86.23 15 48.573 26.757 40.142 55.954c-4.419 8.14 4.641 22.451-9.444 19.661-22.866 3.85-37.401 31.011-27.583 52.107 6.004 14.6 21.763 24.326 37.505 23.185 8.003 28.498 44.202 40.76 69.818 28.563 10.46-7.746 19.314 11.411 32.389 8.317 13.65 2.938 26.24-10.292 34.536-13.959 23.876 19.345 65.228 12.035 78.11-16.77 9.801-1.936 24.855-2.506 32.942-12.426 16.994-15.732 13.992-46.284-5.67-58.478-7.01-7.536-23.11-4.542-27.468-8.054 4.523-24.794-17.385-47.439-41.252-49.791-8.867-2.906-21.485 3.1-27.56 1.474-3.554-17.14-20.043-30.124-37.51-29.776z"
			/>
		</svg>
	);
}
