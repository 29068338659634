import React, { useContext } from 'react';

import * as classes from './longWalk.module.scss';

import { DaylightContext } from '../../contexts/daylightContext';

import Cloud from '../svg/cloud/Cloud';
import Houses from '../svg/houses/Houses';
import RiverSection from '../riverSection/RiverSection';

export default function LongWalk({ houseWrapFontSize }) {
	const { daylight } = useContext(DaylightContext);

	return (
		<div aria-hidden="true">
			<div className={`${classes.sky} ${!daylight ? classes.darkSky : ''}`}>
				<Cloud cloudClass={classes.cloud1} />
				<Cloud cloudClass={classes.cloud2} />
				<Cloud cloudClass={classes.cloud3} />
				<Cloud cloudClass={classes.cloud4} />
				<Cloud cloudClass={classes.cloud5} />

				<div
					className={`${classes.housesWrap} ${
						!daylight ? classes.darkHousesWrap : ''
					}`}
					style={{ fontSize: `${houseWrapFontSize}px` }}
				>
					<div
						className={`${classes.streetlight} ${classes.streetlight__one}`}
					></div>
					<div
						className={`${classes.streetlight} ${classes.streetlight__two}`}
					></div>
					<div
						className={`${classes.streetlight} ${classes.streetlight__three}`}
					></div>

					<Houses classes={classes} />
				</div>

				<RiverSection sail={false} />
			</div>
		</div>
	);
}
