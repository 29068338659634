import React from 'react';

export default function Houses({ classes }) {
	return (
		<>
			<div
				className={`${classes.house} ${classes.house__smallone} ${classes.house__unlit}`}
			>
				<div className={classes.roof}> </div>{' '}
				<div className={classes.wall}>
					<div className={classes.window}>
						<div className={classes.window__line}> </div>{' '}
						<div className={classes.window__sill}> </div>{' '}
					</div>{' '}
					<div className={classes.window}>
						<div className={classes.window__line}> </div>{' '}
						<div className={classes.window__sill}> </div>{' '}
					</div>
					<div className={classes.window}>
						<div className={classes.window__line}> </div>{' '}
						<div className={classes.window__sill}> </div>{' '}
					</div>{' '}
					<div className={classes.door}>
						<div className={classes.door__top}> </div>{' '}
					</div>{' '}
				</div>{' '}
			</div>{' '}
			<div
				className={`${classes.house} ${classes.house__smalltwo} ${classes.house__unlit}`}
			>
				<div className={classes.roof}> </div>{' '}
				<div className={classes.wall}>
					<div className={classes.window}>
						<div className={classes.window__line}> </div>{' '}
						<div className={classes.window__sill}> </div>{' '}
					</div>{' '}
					<div className={classes.window}>
						<div className={classes.window__line}> </div>{' '}
						<div className={classes.window__sill}> </div>{' '}
					</div>{' '}
					<div className={classes.door}> </div>{' '}
				</div>{' '}
			</div>
			<div
				className={`${classes.house} ${classes.house__tall} ${classes.house__lit} ${classes.house__litright}`}
			>
				<div className={classes.roof}> </div>{' '}
				<div className={classes.wall}>
					<div className={classes.windowRowOne}>
						<div className={classes.window}>
							<div className={classes.window__line}> </div>{' '}
							<div className={classes.window__lineVertical}> </div>{' '}
							<div className={classes.window__sill}> </div>{' '}
						</div>{' '}
						<div className={classes.window}>
							<div className={classes.window__line}> </div>{' '}
							<div className={classes.window__lineVertical}> </div>{' '}
							<div className={classes.window__sill}> </div>{' '}
						</div>{' '}
						<div className={classes.window}>
							<div className={classes.window__line}> </div>{' '}
							<div className={classes.window__lineVertical}> </div>{' '}
							<div className={classes.window__sill}> </div>{' '}
						</div>{' '}
					</div>{' '}
					<div className={classes.windowRowTwo}>
						<div className={classes.window}>
							<div className={classes.window__line}> </div>{' '}
							<div className={classes.window__lineVertical}> </div>{' '}
							<div className={classes.window__sill}> </div>{' '}
						</div>{' '}
						<div className={classes.window}>
							<div className={classes.window__line}> </div>{' '}
							<div className={classes.window__lineVertical}> </div>{' '}
							<div className={classes.window__sill}> </div>{' '}
						</div>{' '}
						<div className={classes.window}>
							<div className={classes.window__line}> </div>{' '}
							<div className={classes.window__lineVertical}> </div>{' '}
							<div className={classes.window__sill}> </div>{' '}
						</div>{' '}
					</div>{' '}
					<div className={classes.windowRowThree}>
						<div className={classes.window}>
							<div className={classes.window__line}> </div>{' '}
							<div className={classes.window__lineVertical}> </div>{' '}
							<div className={classes.window__sill}> </div>{' '}
						</div>{' '}
						<div className={classes.twoWindows}>
							<div className={classes.window}>
								<div className={classes.window__line}> </div>{' '}
								<div className={classes.window__lineVertical}> </div>{' '}
								<div className={classes.window__sill}> </div>{' '}
							</div>{' '}
							<div className={classes.window}>
								<div className={classes.window__line}> </div>{' '}
								<div className={classes.window__lineVertical}> </div>{' '}
								<div className={classes.window__sill}> </div>{' '}
							</div>{' '}
						</div>{' '}
						<div className={classes.door}> </div>{' '}
					</div>{' '}
				</div>{' '}
			</div>{' '}
			<div
				className={`${classes.house} ${classes.house__red} ${classes.house__lit} ${classes.house__litleft}`}
			>
				<div className={classes.roof}> </div>{' '}
				<div className={classes.wall}>
					<div className={classes.window}>
						<div className={classes.window__line}> </div>{' '}
						<div className={classes.window__line}> </div>{' '}
						<div className={classes.window__line}> </div>{' '}
						<div className={classes.window__line}> </div>{' '}
						<div className={classes.window__line}> </div>{' '}
						<div className={classes.window__sill}> </div>{' '}
					</div>{' '}
					<div className={classes.window}>
						<div className={classes.window__line}> </div>{' '}
						<div className={classes.window__line}> </div>{' '}
						<div className={classes.window__line}> </div>{' '}
						<div className={classes.window__line}> </div>{' '}
						<div className={classes.window__line}> </div>{' '}
						<div className={classes.window__sill}> </div>{' '}
					</div>{' '}
					<div className={classes.window}>
						<div className={classes.window__line}> </div>{' '}
						<div className={classes.window__line}> </div>{' '}
						<div className={classes.window__line}> </div>{' '}
						<div className={classes.window__line}> </div>{' '}
						<div className={classes.window__line}> </div>{' '}
						<div className={classes.window__sill}> </div>{' '}
					</div>{' '}
					<div className={classes.window}>
						<div className={classes.window__line}> </div>{' '}
						<div className={classes.window__line}> </div>{' '}
						<div className={classes.window__line}> </div>{' '}
						<div className={classes.window__line}> </div>{' '}
						<div className={classes.window__line}> </div>{' '}
						<div className={classes.window__line}> </div>{' '}
						<div className={classes.window__sill}> </div>{' '}
					</div>{' '}
					<div className={classes.door}> </div>{' '}
				</div>{' '}
			</div>
			<div
				className={`${classes.house} ${classes.house__white} ${classes.house__unlit}`}
			>
				<div className={classes.roof}> </div>{' '}
				<div className={classes.wall}>
					<div className={classes.window}>
						<div className={classes.window__sill}> </div>{' '}
						<div className={classes.window__line}> </div>{' '}
					</div>{' '}
					<div className={classes.window}>
						<div className={classes.window__sill}> </div>{' '}
						<div className={classes.window__line}> </div>{' '}
					</div>{' '}
					<div className={classes.window}>
						<div className={classes.window__sill}> </div>{' '}
						<div className={classes.window__line}> </div>{' '}
					</div>{' '}
					<div className={classes.window}>
						<div className={classes.window__sill}> </div>{' '}
						<div className={classes.window__line}> </div>{' '}
					</div>{' '}
					<div className={classes.door}> </div>{' '}
				</div>{' '}
			</div>{' '}
			<div
				className={`${classes.house} ${classes.house__blue} ${classes.house__lit} ${classes.house__litright}`}
			>
				<div className={classes.roof}> </div>{' '}
				<div className={classes.wall}>
					<div className={classes.window}>
						<div className={classes.window__sill}> </div>{' '}
						<div className={classes.window__line}> </div>{' '}
					</div>{' '}
					<div className={classes.window}>
						<div className={classes.window__sill}> </div>{' '}
						<div className={classes.window__line}> </div>{' '}
					</div>
					<div className={classes.door}> </div>{' '}
					<div className={classes.window}>
						<div className={classes.window__sill}> </div>{' '}
						<div className={classes.window__line}> </div>{' '}
					</div>{' '}
				</div>{' '}
			</div>{' '}
			<div
				className={`${classes.house} ${classes.house__lightblue} ${classes.house__lit} ${classes.house__litleft}`}
			>
				<div className={classes.roof}> </div>{' '}
				<div className={classes.wall}>
					<div className={classes.window}>
						<div className={classes.window__sill}> </div>{' '}
						<div className={classes.window__line}> </div>{' '}
					</div>{' '}
					<div className={classes.window}>
						<div className={classes.window__sill}> </div>{' '}
						<div className={classes.window__line}> </div>{' '}
					</div>
					<div className={classes.door}> </div>{' '}
					<div className={classes.window}>
						<div className={classes.window__sill}> </div>{' '}
						<div className={classes.window__line}> </div>{' '}
					</div>{' '}
				</div>{' '}
			</div>{' '}
			<div
				className={`${classes.house} ${classes.house__beige} ${classes.house__unlit}`}
			>
				<div className={classes.roof}> </div>{' '}
				<div className={classes.wall}>
					<div className={classes.window}>
						<div className={classes.window__sill}> </div>{' '}
						<div className={classes.window__line}> </div>{' '}
					</div>{' '}
					<div className={classes.window}>
						<div className={classes.window__sill}> </div>{' '}
						<div className={classes.window__line}> </div>{' '}
					</div>
					<div className={classes.door}> </div>{' '}
					<div className={classes.window}>
						<div className={classes.window__sill}> </div>{' '}
						<div className={classes.window__line}> </div>{' '}
					</div>{' '}
				</div>{' '}
			</div>{' '}
			<div
				className={`${classes.house} ${classes.house__stepone} ${classes.house__unlit}`}
			>
				<div className={classes.roof}> </div>{' '}
				<div className={classes.wall}>
					<div className={classes.window}>
						<div className={classes.window__sill}> </div>{' '}
						<div className={classes.window__line}> </div>{' '}
					</div>{' '}
					<div className={classes.window}>
						<div className={classes.window__sill}> </div>{' '}
						<div className={classes.window__line}> </div>{' '}
					</div>
					<div className={classes.window}>
						<div className={classes.window__sill}> </div>{' '}
						<div className={classes.window__line}> </div>{' '}
					</div>{' '}
					<div className={classes.window}>
						<div className={classes.window__sill}> </div>{' '}
						<div className={classes.window__line}> </div>{' '}
					</div>{' '}
					<div className={classes.door}> </div>{' '}
				</div>{' '}
			</div>{' '}
			<div
				className={`${classes.house} ${classes.house__steptwo} ${classes.house__lit} ${classes.house__litright}`}
			>
				<div className={classes.roof}> </div>{' '}
				<div className={classes.wall}>
					<div className={classes.window}>
						<div className={classes.window__sill}> </div>{' '}
						<div className={classes.window__line}> </div>{' '}
					</div>{' '}
					<div className={classes.window}>
						<div className={classes.window__sill}> </div>{' '}
						<div className={classes.window__line}> </div>{' '}
					</div>
					<div className={classes.window}>
						<div className={classes.window__sill}> </div>{' '}
						<div className={classes.window__line}> </div>{' '}
					</div>{' '}
					<div className={classes.window}>
						<div className={classes.window__sill}> </div>{' '}
						<div className={classes.window__line}> </div>{' '}
					</div>{' '}
					<div className={classes.door}> </div>{' '}
				</div>{' '}
			</div>
			<div
				className={`${classes.house} ${classes.house__newblueOne} ${classes.house__lit} ${classes.house__litleft}`}
			>
				<div className={classes.roof}> </div>{' '}
				<div className={classes.wall}>
					<div className={classes.window}>
						<div className={classes.window__sill}> </div>{' '}
						<div className={classes.window__line}> </div>{' '}
					</div>{' '}
					<div className={classes.window}>
						<div className={classes.window__sill}> </div>{' '}
						<div className={classes.window__line}> </div>{' '}
					</div>
					<div className={classes.window}>
						<div className={classes.window__sill}> </div>{' '}
						<div className={classes.window__line}> </div>{' '}
					</div>{' '}
					<div className={classes.window}>
						<div className={classes.window__sill}> </div>{' '}
						<div className={classes.window__line}> </div>{' '}
					</div>{' '}
					<div className={classes.door}> </div>{' '}
				</div>{' '}
			</div>{' '}
			<div
				className={`${classes.house} ${classes.house__newblueTwo} ${classes.house__unlit}`}
			>
				<div className={classes.roof}> </div>{' '}
				<div className={classes.wall}>
					<div className={classes.window}>
						<div className={classes.window__sill}> </div>{' '}
						<div className={classes.window__line}> </div>{' '}
					</div>{' '}
					<div className={classes.window}>
						<div className={classes.window__sill}> </div>{' '}
						<div className={classes.window__line}> </div>{' '}
					</div>
					<div className={classes.window}>
						<div className={classes.window__sill}> </div>{' '}
						<div className={classes.window__line}> </div>{' '}
					</div>{' '}
					<div className={classes.window}>
						<div className={classes.window__sill}> </div>{' '}
						<div className={classes.window__line}> </div>{' '}
					</div>{' '}
					<div className={classes.door}> </div>{' '}
				</div>{' '}
			</div>{' '}
		</>
	);
}
